<template>
  <div>
    <van-nav-bar
      title="收入"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-grid :columnNum="2" size="16px">
      <van-grid-item icon="gold-coin-o">
        <div slot="text">
          <div>今日收入</div>
          <div>{{ today_income }}</div>
        </div>
      </van-grid-item>
      <van-grid-item icon="chart-trending-o">
        <div slot="text">
          <div>本月收入</div>
          <div>{{ month_income }}</div>
        </div>
      </van-grid-item>
    </van-grid>
    <van-row>
      <van-col span="4">金额</van-col>
      <van-col span="8">类型</van-col>
      <van-col span="12">时间</van-col>
    </van-row>
    <van-row v-for="item in dataList" :key="item.id" style="padding-top: 5px">
      <van-col span="4">{{ item.amount.toFixed(2) }}</van-col>
      <van-col span="8" v-if="item.reward_type == 0">推荐奖励</van-col>
      <van-col span="8" v-else-if="item.reward_type == 1">额外奖励</van-col>
      <van-col span="8" v-else-if="item.reward_type == 2">孔明奖励</van-col>
      <van-col span="8" v-else-if="item.reward_type == 98">退款扣除</van-col>
      <van-col span="8" v-else-if="item.reward_type == 99">核验扣除</van-col>
      <van-col span="12">{{ item.created_time }}</van-col>
    </van-row>
    <div class="my_pager">
      <van-pagination
        v-model="currentPage"
        :page-count="pageCount"
        mode="simple"
        @change="changePage"
      />
    </div>
    <div class="my_tips">
      <b>用户须知：</b><br />
      1.「额外奖励」的计算时间为每月的10～17号，核算数据为上个月的数据<br />
      2.交易发生退款时，对应的推荐数量、推荐奖励、额外奖励将会扣除<br />
      3.收入有7天冻结期，冻结期过后即可提现<br />
    </div>
    <CopyRight></CopyRight> 
  </div>
</template>

<script>
import CopyRight from "@/components/CopyRight.vue";
import axios from "axios";
export default {
  data() {
    return {
      today_income: 0,
      month_income: 0,
      dataList: [],
      currentPage: 1,
      pageCount: 1,
    };
  },
  components: {
    CopyRight,
  },
  mounted() {
    this.loadTotalData();
    this.loadData();
  },

  methods: {
    onClickLeft() {
      this.$router.back();
    },

    changePage(page) {
      this.currentPage = page;
      this.loadData();
    },

    loadTotalData() {
      let token = window.sessionStorage.getItem("token");
      axios
        .get(
          "api/income/statistics?token=" + token
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.today_income = res.data.data.today_income;
            this.month_income = res.data.data.month_income;
          }
        });
    },

    loadData() {
      let token = window.sessionStorage.getItem("token");
      axios
        .get(
          "api/income/list?page=" + this.currentPage + "&token=" + token
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.dataList = res.data.data.data_list;
            this.pageCount = res.data.data.total_page;
          } else {
            this.$toast.fail(res.data.message);
          }
        });
    },
  },
};
</script>
